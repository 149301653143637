import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import * as S from './styled'
import logo from '../../../static/assets/logoPositive.svg'

const Footer = () => {
  const data = useStaticQuery(
    graphql`
      query {
        phones: markdownRemark(frontmatter: { key: { eq: "phones" } }) {
          frontmatter {
            phoneFoz
            phoneBra
          }
        }
        social: markdownRemark(frontmatter: { key: { eq: "social" } }) {
          frontmatter {
            facebook
            instagram
            messenger
            whatsapp
          }
        }
        oab: markdownRemark(frontmatter: { key: { eq: "oab" } }) {
          frontmatter {
            oab
          }
        }
      }
    `
  )
  return (
    <S.Footer>
      <S.FooterWrapper>
        <S.FirstColumn>
          <S.Logo src={logo} alt="" />
        </S.FirstColumn>
        <S.SecondColumn>
          <S.Address>
            Foz do Iguaçu - PR
            <br />
            R. Almirante Barroso, 837
            <br />
            Centro - 85851-010
            <br /> {data.phones.frontmatter.phoneFoz}
          </S.Address>
        </S.SecondColumn>
        <S.LastColumn>
          <S.Address>
            Brasília - DF
            <br />
            {data.phones.frontmatter.phoneBra}
          </S.Address>
          <S.SocialIcons>
            <a
              href={data.social.frontmatter.facebook}
              alt="Facebook"
              aria-label="WhatsApp"
              rel="noreferrer noopener"
              target="_blank"
            >
              <S.FacebookIcon />
            </a>
            <a
              href={data.social.frontmatter.instagram}
              alt="Instagram"
              aria-label="WhatsApp"
              rel="noreferrer noopener"
              target="_blank"
            >
              <S.InstagramIcon />
            </a>
            <a
              href={data.social.frontmatter.messenger}
              alt="Messenger"
              aria-label="WhatsApp"
              rel="noreferrer noopener"
              target="_blank"
            >
              <S.MessengerIcon />
            </a>
            <a
              href={
                'https://api.whatsapp.com/send?phone=55' +
                data.social.frontmatter.whatsapp
              }
              alt="WhatsApp"
              aria-label="WhatsApp"
              rel="noreferrer noopener"
              target="_blank"
            >
              <S.WhatsappIcon />
            </a>
          </S.SocialIcons>
        </S.LastColumn>
      </S.FooterWrapper>
      <S.CopyWrapper>
        Medeiros Miranda {'&'} Heck Sociedade de Advogados
        <br />
        {data.oab.frontmatter.oab}
        <br />
        Desenvolvido por{' '}
        <a
          href="https://marscollective.co"
          alt="Mars Collective"
          rel="noreferrer noopener"
          target="_blank"
        >
          Mars Collective
        </a>
        <br />
        Copyright © {new Date().getFullYear()}
      </S.CopyWrapper>
    </S.Footer>
  )
}

export default Footer
