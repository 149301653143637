import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import * as S from './styled'
import logo from '../../../static/assets/logo.svg'

const Nav = () => {
  const data = useStaticQuery(
    graphql`
      query {
        phones: markdownRemark(frontmatter: { key: { eq: "phones" } }) {
          frontmatter {
            phoneFoz
            phoneBra
          }
        }
      }
    `
  )

  const [open, setOpen] = useState(false)

  return (
    <S.Nav>
      <S.Logo src={logo} alt="" />
      <S.StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </S.StyledBurger>
      <S.List open={open} onClick={() => setOpen(!open)}>
        <S.ListItem>
          <S.LinkItemHome to="/">Home</S.LinkItemHome>
        </S.ListItem>
        <S.ListItem>
          <S.LinkItem to="/#sobre">Sobre</S.LinkItem>
        </S.ListItem>
        <S.ListItem>
          <S.LinkItem to="/#areas-de-atuacao">Áreas de atuação</S.LinkItem>
        </S.ListItem>
        <S.ListItem>
          <S.LinkItem to="/#advogados">Advogados</S.LinkItem>
        </S.ListItem>
        <S.ListItem>
          <S.LinkItem to="/#informativos">Informativos</S.LinkItem>
        </S.ListItem>
        <S.ListItem>
          <S.LinkItem to="/#contato">Contato</S.LinkItem>
        </S.ListItem>
        <S.ListItem>
          <S.PhoneWrapper>
            <S.PhoneItem>{data.phones.frontmatter.phoneFoz}</S.PhoneItem>
            <S.PhoneItem>{data.phones.frontmatter.phoneBra}</S.PhoneItem>
          </S.PhoneWrapper>
        </S.ListItem>
        <S.ListItem>
          <S.LinkItem to="/#contato">
            <button>Entre em contato</button>
          </S.LinkItem>
        </S.ListItem>
      </S.List>
    </S.Nav>
  )
}

export default Nav
