import styled from 'styled-components'
import media from 'styled-media-query'
import { Link } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

export const Nav = styled.nav`
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px 20px;
  background: #fff;
  z-index: 5;
`

export const Logo = styled.img`
  ${media.lessThan('1500px')`
    width: 260px;
  `}
`

export const List = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  list-style: none;

  ${media.lessThan('1200px')`
    flex-flow: column nowrap;
    background-color: #FFFCF3;
    position: fixed;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
  `}
`

export const ListItem = styled.li`
  align-self: center;
  font-weight: 600;
  color: #707070;
  text-transform: uppercase;
  margin-left: 30px;

  a {
    color: #707070;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ${media.lessThan('1500px')`
    font-size: 14px;
    margin-left: 20px;
  `}

  ${media.lessThan('1200px')`
    padding: 10px 20px;
  `}
`

export const LinkItemHome = styled(Link)``

export const LinkItem = styled(AnchorLink)``

export const StyledBurger = styled.div`
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 25px;
  right: 20px;
  z-index: 20;
  display: none;
  cursor: pointer;

  ${media.lessThan('1200px')`
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  `}

  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${({ open }) => (open ? '#ccc' : '#333')};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;

    &:nth-child(1) {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    &:nth-child(2) {
      transform: ${({ open }) => (open ? 'translateX(100%)' : 'translateX(0)')};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }

    &:nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`

export const PhoneWrapper = styled.div`
  text-align: center;
`

export const PhoneItem = styled.span`
  display: block;
`
