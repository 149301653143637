import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
  }

  body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
  }

  h1, h2 {
    color: #CFB53B;
    margin-bottom: 2rem;
  }

  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-weight: 400;
    font-size: 22px;
  }

  h4 {
    font-weight: 400;
  }

  .border {
    height: 6px;
    width: 130px;
    margin: -1rem 0 2rem;
    background: #8B8B8B;
  }

  p {
    margin-bottom: 1rem;
  }

  button {
    background: #CFB53B;
    color: #fff;
    font-weight: 600;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    transition: 0.5s;
    cursor: pointer;

    &:hover {
      background: #C19816;
      box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.3);
    }
  }
`

export default GlobalStyles
