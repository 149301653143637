import styled from 'styled-components'
import media from 'styled-media-query'
import { FacebookSquare as Facebook } from '@styled-icons/boxicons-logos/FacebookSquare'
import { Instagram } from '@styled-icons/boxicons-logos/Instagram'
import { Messenger } from '@styled-icons/boxicons-logos/Messenger'
import { Whatsapp } from '@styled-icons/boxicons-logos/Whatsapp'

export const Footer = styled.footer`
  background: #373737;
  color: #fff;
  text-align: center;
  padding: 5% 20px 30px;

  ${media.lessThan('medium')`
    padding: 10% 20px;
  `}
`

export const FooterWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 2rem;

  ${media.lessThan('large')`
    flex-direction: column;
  `}
`

export const FirstColumn = styled.div`
  width: 35%;

  ${media.lessThan('large')`
    display: none;
  `}
`

export const SecondColumn = styled.div`
  width: 30%;

  ${media.lessThan('large')`
    width: 100%;
  `}
`

export const LastColumn = styled.div`
  width: 35%;
  display: flex;
  justify-content: space-around;

  ${media.lessThan('large')`
    flex-direction: column;
    width: 100%;
    margin: 20px 0;
  `}
`

export const Logo = styled.img``

export const Address = styled.div``

export const SocialIcons = styled.div`
  a {
    color: white;
    transition: 0.3s;

    &:hover {
      color: #cfb53b;
    }
  }

  ${media.lessThan('large')`
    margin-top: 20px;
  `}
`

export const FacebookIcon = styled(Facebook)`
  height: 30px;
  margin-right: 10px;
`

export const InstagramIcon = styled(Instagram)`
  height: 30px;
  margin-right: 10px;
`

export const MessengerIcon = styled(Messenger)`
  height: 30px;
  margin-right: 10px;
`

export const WhatsappIcon = styled(Whatsapp)`
  height: 30px;
`

export const CopyWrapper = styled.div`
  font-size: 12px;
  text-align: center;

  a {
    color: white;
    font-weight: 700;
    text-decoration: none;
  }
`
